import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

import useBrowserApiHelpers from '@/composables/use-browser-api-helpers';
import BookingExperience from '@/enums/BookingExperience';
import Designation from '@/enums/Designation';
import processInputsData from '@/helpers/search';
import { i18n } from '@/i18n';
import SearchInputs from '@/models/SearchInputs';

export default {
  namespaced: true,
  state: {
    conversionCurrency: '',
    conversionRate: 1,
    dates: {
      date_from: DateTime.local(),
      date_to: DateTime.local(),
      old_date_from: undefined,
    },
    inputsSnapshot: new SearchInputs(),
    inputs: new SearchInputs(),
    isVcpr: undefined,
    page: 1,
    showFilters: false,
  },
  mutations: {
    // TODO: Does timezone interact here?
    async setInputs(state, data) {
      const processedData = processInputsData(data);
      Object.assign(state.inputs, processedData);
      await this.commit('search/updateSearchParamsLocalStorage', data);
    },
    async setTempInputs(state, data) {
      const processedData = processInputsData(data);
      Object.assign(state.inputsSnapshot, processedData);
    },
    localStorageDelete(state, key) {
      const { localStorageAvailable } = useBrowserApiHelpers();

      if (!localStorageAvailable()) {
        return;
      }

      const params = JSON.parse(localStorage.getItem('searchParams'));
      delete params[key];
      localStorage.setItem('searchParams', JSON.stringify(params));
    },
    async updateSearchParamsLocalStorage(state, data) {
      const { localStorageAvailable } = useBrowserApiHelpers();

      if (!localStorageAvailable()) {
        return;
      }

      let params = localStorage.getItem('searchParams');
      params = params ? JSON.parse(params) : {};
      params = { ...params, ...data };
      localStorage.setItem('searchParams', JSON.stringify(params));
    },
    setConversionCurrency(state, conversionCurrency) {
      state.conversionCurrency = conversionCurrency;
    },
    setConversionRate(state, conversionRate) {
      state.conversionRate = conversionRate;
    },
    setDates(state, data) {
      if (!data.date_from) {
        data.date_from = null;
      } else if (data.date_from && !DateTime.isDateTime(data.date_from)) {
        data.date_from = DateTime.fromISO(data.date_from, {
          zone: data.timezone,
        });
      }
      if (!data.date_to) {
        data.date_to = null;
      } else if (data.date_to && !DateTime.isDateTime(data.date_to)) {
        data.date_to = DateTime.fromISO(data.date_to, {
          zone: data.timezone,
        });
      }
      Object.assign(state.dates, data);
    },
    takeSnapshot(state) {
      state.inputsSnapshot = cloneDeep(state.inputs);
    },
    setIsVcprSearch(state, value) {
      state.isVcpr = value;
    },
    setPage(state, value) {
      state.page = value;
    },
    setShowFilters(state, value) {
      state.showFilters = value;
    },
  },
  getters: {
    getConversionCurrency(state) {
      return state.conversionCurrency;
    },
    getConversionRate(state) {
      return state.conversionRate;
    },
    getInputs(state) {
      return state.inputs;
    },
    isToday(state) {
      const today = DateTime.local().startOf('day');

      return (
        DateTime.isDateTime(state.dates.date_from)
        && state.dates.date_from.get('day') === today.get('day')
        && state.dates.date_from.get('month') === today.get('month')
        && state.dates.date_from.get('year') === today.get('year')
      );
    },
    isTomorrow(state) {
      const today = DateTime.local().startOf('day');

      return (
        DateTime.isDateTime(state.dates.date_from)
        && state.dates.date_from.get('day') === today.plus({ days: 1 }).get('day')
        && state.dates.date_from.get('month') === today.plus({ days: 1 }).get('month')
        && state.dates.date_from.get('year') === today.plus({ days: 1 }).get('year')
      );
    },
    displayDateShort(state, getters) {
      if (!state.dates.date_from || getters.isToday) {
        return i18n.t('search.next-available-appointment');
      }

      if (getters.isTomorrow) {
        return i18n.t('search.tomorrow');
      }

      return (
        DateTime.isDateTime(state.dates.date_from)
        && state.dates.date_from
          .setLocale(i18n.locale)
          .toFormat('ccc, LLL d')
      );
    },
    displayDate(state, getters) {
      if (!state.dates.date_from) {
        return i18n.t('search.choose-a-date');
      }

      if (getters.isToday) {
        return i18n.t('search.today');
      }

      if (getters.isTomorrow) {
        return i18n.t('search.tomorrow');
      }

      return (
        DateTime.isDateTime(state.dates.date_from)
        && state.dates.date_from
          .setLocale(i18n.locale)
          .toFormat('ccc, LLLL d, y')
      );
    },
    displayCity(state, getters, rootGetters) {
      let inputs = [];

      if (getters.hasCity) {
        inputs = [
          state.inputs.city,
          state.inputs.province,
        ];
      } else if (rootGetters.isGlobalUser && getters.hasCountry) {
        inputs = [
          state.inputs.country,
          state.inputs.province,
        ];
      }

      return inputs.filter(Boolean).join(', ')
        || i18n.t('search.add-your-location');
    },
    hasCity(state, getters, rootState, rootGetters) {
      if (rootGetters.isGlobalUser) {
        return !!(state.inputs.city);
      }

      return !!(state.inputs.city && state.inputs.province);
    },
    hasCountry(state) {
      return !isEmpty(state.inputs.country);
    },
    hasName(state) {
      return !isEmpty(state.inputs.full_name);
    },
    hasSpeciesTreated(state) {
      return !isEmpty(state.inputs.species_treated?.filter(Boolean));
    },
    hasAreaOfInterest(state) {
      return !isEmpty(state.inputs.areas_of_interest?.filter(Boolean));
    },
    hasConcern(state) {
      return !isEmpty(state.inputs.concerns);
    },
    hasLanguage(state) {
      return !isEmpty(state.inputs.language);
    },
    isVcprSearch(state) {
      return state.isVcpr;
    },
    isWellness(state) {
      return state.inputs.designation === Designation.Technician;
    },
    canSearch(state, getters) {
      return (
        Designation.getValues().includes(state.inputs.designation)
          && getters.hasCity
      ) || (
        state.inputs.full_name?.length >= 4
          && state.inputs.province
          && state.inputs.country
      ) || state.inputs.search_uuid;
    },
    getSearchPet(state) {
      return state.inputs.pet_uuid;
    },
    getShowFilters(state) {
      return state.showFilters;
    },
    getTempInputs(state) {
      return state.inputsSnapshot;
    },
    // We should now use the pet's booking_experience value for any context where the pet is known
    hidePriceForSearchPet(state, getters, rootState, rootGetters) {
      const searchPet = getters.getSearchPet && rootGetters['pets/getPets'].find((pet) => pet.uuid === getters.getSearchPet);

      return searchPet
        ? searchPet.booking_experience === BookingExperience.HiddenPricing
        : rootGetters.hidePricing;
    },
  },
  actions: {
    async fetchCurrencyConversion({ rootState, commit }, currency) {
      await this.$app.$api
        .get(`p/exchange/${rootState.site.currency}/${currency}`)
        .then((response) => {
          commit('setConversionRate', Object.values(response.data.data)[0]);
          commit('setConversionCurrency', currency);
        });
    },
  },
};
